<template>
  <div>
    <b-button class="eh-action-button" @click="showNewReportFormModal = true">
      Új vény jelentés
    </b-button>
    <fp-create-bever
      v-model="showNewReportFormModal"
      @refreshList="refreshing = true"
    />
    <!-- TODO: szűrés -->
    <!-- <div class="eh-flex-container">
      <fp-input
        class="eh-margin-right eh-fix-filter-input-width"
        :label="$t('base.basic.filter')"
        @change="filterList"
        v-model="filter"
      />
      <fp-date-range-button
        label="Kiadás dátuma"
        v-model="filledDateFilter"
        @change="filterList"
      />
    </div> -->
    <fp-bever-list :refreshing.sync="refreshing" />
  </div>
</template>
<script>
export default {
  name: "RecipeReportManager",
  data() {
    return {
      showNewReportFormModal: false,
      refreshing: false,
    };
  },
  async mounted() {
    this.$store.dispatch("setPageTitle", "Vény jelentések");
  },
};
</script>
